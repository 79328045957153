.game {
  overflow: hidden;

  iframe {
    width: 100%;
    height: 100vh;
  }

  .backIcon {
    position: fixed;
    top: 32px;
    left: 32px;
    width: 72px;
    height: 72px;
  }
}
