html {
  font-size: 62.5%;
}

html body #root .App {
  min-height: 100vh;
}

* {
  box-sizing: border-box;
}
