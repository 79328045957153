.home {
  position: relative;
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
  background-color: red;

  .header {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 32px;
    font-size: 24px;
    color: #fff;
    font-weight: 600;

    .title {
      width: 320px;
      height: 40px;
      background-image: url('../../assets/images/title.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    .rankBtn {
      position: absolute;
      top: 32px;
      right: 32px;
      width: 120px;
      height: 54px;
      background-image: url('../../assets/images/rank.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }

  .content {
    position: relative;
    width: 750px;
    height: 1106px;
    margin: 60px auto 0;
    background-image: url('../../assets/images/path.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .stage {
      width: 120px;
      height: 120px;

      &.question {
        background-image: url('../../assets/images/gift.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;

        &.completed {
          background-image: url('../../assets/images/gift-active.png');
        }
      }

      &.game {
        background-image: url('../../assets/images/game.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;

        &.completed {
          background-image: url('../../assets/images/game-active.png');
        }
      }
    }

    .stageTitle {
      width: 118px;
      height: 36px;
    }

    .stage0 {
      position: absolute;
      top: -10px;
      left: 340px;

      .title0 {
        position: absolute;
        top: 40px;
        left: 140px;
        background-image: url('../../assets/images/s1-active.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }

    .stage1 {
      position: absolute;
      top: 82px;
      left: 120px;

      .title1 {
        position: absolute;
        bottom: -50px;
        left: 4px;
        background-image: url('../../assets/images/s2-active.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }

    .stage2 {
      position: absolute;
      top: 200px;
      right: 300px;

      .title2 {
        position: absolute;
        bottom: -50px;
        left: 4px;
        background-image: url('../../assets/images/s3-active.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }

    .stage3 {
      position: absolute;
      top: 400px;
      right: 80px;

      .title3 {
        position: absolute;
        bottom: -50px;
        left: 4px;
        background-image: url('../../assets/images/s4-active.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }

    .stage4 {
      position: absolute;
      top: 680px;
      right: 160px;

      .title4 {
        position: absolute;
        top: 50px;
        left: -140px;
        background-image: url('../../assets/images/s5-active.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }
  }

  .footer {
    position: absolute;
    bottom: 12px;
    left: 12px;
    right: 12px;
    height: 120px;
    padding: 8px;
    background-color: #fff;
    border-radius: 12px;
  }
}
