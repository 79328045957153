.rank {
  width: 100%;
  height: 100vh;
  background-image: url('../../assets/images/bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  overflow: hidden;

  .header {
    display: flex;
    align-items: center;
    padding: 30px;

    .backIcon {
      width: 72px;
      height: 72px;
    }

    .rankTitle {
      width: 204px;
      height: 60px;
      margin: 0 auto;
    }
  }

  .content {
    position: relative;
    height: 100%;
    padding-top: 330px;

    .topRank {
      display: flex;
      justify-content: space-around;

      .avatar {
        position: absolute;
        top: -68px;
        left: 38px;
        width: 134px;
        height: 134px;
        border-radius: 100%;
        background-color: #fff;
        box-shadow: rgba(0, 0, 0, 0.5) 0 2px 4px;

        img {
          width: 100%;
          height: 100%;
          padding: 1px;
          border-radius: 100%;
        }
      }

      .username {
        font-size: 32px;
        text-align: center;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 22px;
        padding-top: 86px;
      }

      .rankNum {
        position: absolute;
        background-repeat: no-repeat;
        background-size: 100% 100%;

        &.one {
          top: 148px;
          left: 72px;
          width: 64px;
          height: 138px;
          background-image: url('../../assets/images/1.png');
        }

        &.two {
          top: 140px;
          left: 66px;
          width: 74px;
          height: 114px;
          background-image: url('../../assets/images/2.png');
        }

        &.three {
          top: 128px;
          left: 74px;
          width: 64px;
          height: 98px;
          background-image: url('../../assets/images/3.png');
        }
      }

      .rank2 {
        position: relative;
        width: 210px;
        height: 346px;
        margin-top: -80px;
        background-image: url('../../assets/images/top2.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }

      .rank1 {
        position: relative;
        width: 210px;
        height: 384px;
        margin-top: -140px;
        background-image: url('../../assets/images/top1.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;

        .crown {
          position: absolute;
          top: -70px;
          left: -20px;
          width: 178px;
          height: 88px;
          background-image: url('../../assets/images/capIcon.png');
          background-repeat: no-repeat;
          background-size: 100% 100%;
        }
      }

      .rank3 {
        position: relative;
        width: 210px;
        height: 346px;
        margin-top: -10px;
        background-image: url('../../assets/images/top3.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }

    .rankList {
      position: absolute;
      top: 500px;
      bottom: 0;
      width: 100%;
      height: calc(100vh - 600px);
      padding-top: 100px;
      background-image: url('../../assets/images/arcBg.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;

      .rankContent {
        height: 100%;
        overflow-y: auto;
        padding-bottom: 116px;
      }

      .rankItem {
        display: flex;
        align-items: center;
        padding: 8px 100px 8px 50px;

        &.my {
          width: 100%;
          background-image: url('../../assets/images/fixed.png');
          background-repeat: no-repeat;
          background-size: 100% 100%;
        }

        &.fixed {
          position: fixed;
          bottom: 0;
          width: 100%;
          background-image: url('../../assets/images/fixed.png');
          background-repeat: no-repeat;
          background-size: 100% 100%;
        }

        .index {
          display: inline-block;
          width: 80px;
          font-size: 40px;
          font-family: DIN-BlackItalic, DIN;
          font-weight: bolder;
          line-height: 48px;
          color: #333333;

          &.one {
            color: #ec783b;
          }

          &.two {
            color: #ffb206;
          }

          &.three {
            color: #5acc75;
          }
        }

        .avatar {
          display: inline-block;
          width: 100px;
          height: 100px;
          margin: 0 40px 0 24px;
          border-radius: 100%;
          box-shadow: rgba(0, 0, 0, 0.2) 0 4px 8px;

          img {
            width: 100%;
            height: 100%;
            padding: 1px;
            border-radius: 100%;
          }
        }

        .username {
          flex: 1;
          font-size: 32px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: 44px;
        }

        .score {
          font-size: 36px;
          font-family: DinovaBlack;
          color: #333333;
          font-weight: bolder;
          line-height: 44px;
        }
      }
    }
  }
}
