.question {
  width: 100%;
  height: 100vh;
  background-image: url('../../assets/images/questionBg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  overflow: hidden;

  .container {
    height: calc(100vh - 68px);
    overflow-y: auto;
    padding-bottom: 30px;
  }

  .completed {
    margin-top: 200px;

    .gif {
      display: flex;
      justify-content: center;
      width: 500px;
      margin: 0 auto;
    }

    .left {
      width: 250px;
      height: 250px;
      background-image: url('../../assets/images/L.gif');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    .right {
      width: 250px;
      height: 250px;
      background-image: url('../../assets/images/R.gif');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    .successBtn {
      width: 480px;
      height: 120px;
      margin: 100px auto 0;
      background-image: url('../../assets/images/finish.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

  }

  .header {
    display: flex;
    align-items: center;
    padding: 30px;

    .backIcon {
      width: 72px;
      height: 72px;
    }

    .questionTitle {
      width: 272px;
      height: 60px;
      margin: 0 auto;
    }
  }

  .questionContent {
    width: 716px;
    height: 446px;
    margin: 24px auto 0;
    padding: 76px 52px;
    background-image: url('../../assets/images/card.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .titleTip {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-size: 36px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #666666;
        line-height: 50px;

        &.step {
          color: #EB612A;
        }

        &.free {
          font-size: 36px;
          font-family: DIN-BlackItalic, DIN;
          font-weight: bolder;
          color: #5acc75;
          line-height: 22px;
        }
      }
    }

    .content {
      padding-top: 18px;
      font-size: 36px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #666666;
      line-height: 50px;
    }
  }

  .anwserList {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    text-align: center;

    .answerItem {
      display: inline-block;
      width: 480px;
      height: 120px;
      margin: 8px 0;
      font-size: 40px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      line-height: 120px;
      background-image: url('../../assets/images/answerItem.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;

      &.current {
        background-image: url('../../assets/images/answerActive.png');
      }
    }
  }

  .nextBtn {
    width: 480px;
    height: 120px;
    margin: 20px auto 0;
    background-image: url('../../assets/images/nextIcon.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;

    &.disabled {
      background-image: url('../../assets/images/nextIcon-disable.png');
    }

    &.finish {
      background-image: url('../../assets/images/finish.png');
    }
  }
}
